import React, { useLayoutEffect } from "react";
import { Work } from ".";
import arCoke from "../assets/projects/coca.jpg";
import fifa from "../assets/projects/fifa1.png";
import nunet from "../assets/projects/nunet.png";
import block from "../assets/projects/block.png";
import hotovo from "../assets/images/htv-meansdone-01.png";
import vin from "../assets/projects/vin.jpg";
import nodeImg from "../assets/images/stacks/icons8-nodejs-48.png";
import dockerImg from "../assets/images/stacks/docker.png";
import pythonImg from "../assets/images/stacks/Python.png";
import firebaseImg from "../assets/images/stacks/firebase.png";
import solidityImg from "../assets/images/stacks/solidty.png";
import goImg from "../assets/images/stacks/icons8-golang-48.png";
import reactImg from "../assets/images/stacks/icons8-react-40.png";
import puppeteerImg from "../assets/images/stacks/puppeteer.png";
import vueImg from "../assets/images/stacks/vue.png";
import mediaPipeImg from "../assets/images/stacks/mp.png";
import grafanaImg from "../assets/images/stacks/grafana.png";
import ethereumImg from "../assets/images/stacks/ethereum.png";
import chainlinkImg from "../assets/images/stacks/chainlink.png";
import sqlImg from "../assets/images/stacks/sql.png";
import apiImg from "../assets/images/stacks/api.png";
import mssqlImg from "../assets/images/stacks/mssql.png";
import angularImg from "../assets/images/stacks/angular.png";

const urls = {
  angular: "https://angular.io/ ",
  node: "https://nodejs.org/en/",
  react: "https://reactjs.org/",
  firebase: "https://firebase.google.com/",
  mediaPipe: "https://mediapipe.dev/",
  puppeteer: "https://pptr.dev/",
  docker: "https://www.docker.com/",
  python: "https://www.python.org/",
  grafana: "https://grafana.com/",
  solidity: "https://docs.soliditylang.org/en/v0.8.12/",
  chainlink: "https://chain.link/",
  ethereum: "https://ethereum.org/en/",
  vuejs: "https://vuejs.org/",
  nestjs: "https://nestjs.com/",
  sheets: "https://sheets.google.com/",
  golang: "https://go.dev/",
  sqlserver: "https://www.microsoft.com/en-us/sql-server",
  typescript: "https://www.typescriptlang.org/",
};
const cardContent = [
  {
    imageTop: arCoke,
    title: "Coca-Cola Family Trivia Challenge App",
    description: `
            A beautifully designed application to connect family member 
            while having fun at Chinese Holiday. Has advanced face detection features
            to allow users easily interact and respond to questions by their movement.
            Funded by CocaCola.`,
    imageIcons: [
      {
        img: nodeImg,
        title: "",
        url: urls.node,
      },
      {
        img: reactImg,
        title: "ReactJS",
        url: urls.react,
      },

      {
        img: firebaseImg,
        title: "Firebase",
        url: urls.firebase,
      },
      {
        img: mediaPipeImg,
        title: "MediaPipe",
        url: urls.mediaPipe,
      },
    ],
    category: "Webapp Development",
    link: "coca-cola-trivia",
  },
  {
    imageTop: fifa,
    title: "EA Sports FIFA Web Penetration Test",
    description: `
            A sophisticated bot system that scrapes FIFA player transfer data from EA website
            using different advanced algorithms in order to bypass and improve EA web security quality.`,
    imageIcons: [
      {
        img: nodeImg,
        title: "",
        url: urls.node,
      },
      {
        img: puppeteerImg,
        title: "Puppeteer",
        url: urls.puppeteer,
      },
      {
        img: dockerImg,
        title: "Docker",
        url: urls.docker,
      },
      {
        img: firebaseImg,
        title: "Firebase",
        url: urls.firebase,
      },
    ],
    category: "Quality Assurance Development",
    link: "ea-fifa",
  },
  {
    imageTop: nunet,
    title: "SyNet Computing Platform",
    description: `
            An advanced decentralized computing platform that enables users to run tasks on a network of machines.
            Developed system supports sophisticated orchestration of task and computing resource management.        
        `,
    imageIcons: [
      {
        img: pythonImg,
        title: "Python",
        url: urls.python,
      },
      {
        img: dockerImg,
        title: "Docker",
        url: urls.docker,
      },
      {
        img: reactImg,
        title: "ReactJS",
        url: urls.react,
      },
      {
        img: grafanaImg,
        title: "Grafana",
        url: urls.grafana,
      },
    ],
    category: "SaaS Development",
    link: "synet-computing",
  },
  {
    imageTop: block,
    title: "Prism NFT MarketPlace",
    description: `
            A state of the art blockchain that serves as a 
            secure and easy NFT marketplace to trade NFT assets.
            Funded by NuPay Payment Technologies, a subsidiary of Altron Corporation.`,
    imageIcons: [
      {
        img: solidityImg,
        title: "Solidity",
        url: urls.solidity,
      },
      {
        img: chainlinkImg,
        title: "Chainlink",
        url: urls.chainlink,
      },
      {
        img: ethereumImg,
        title: "Ethereum",
        url: urls.ethereum,
      },
      {
        img: vueImg,
        title: "VueJS",
        url: urls.vuejs,
      },
    ],
    category: "SaaS Development",
    link: "blockchain-trade",
  },
  {
    imageTop: hotovo,
    title: "Hotovo",
    description: `
            Provided excellent developers to satisfy senior developer positions. 
            Contributed complex implementations and implemented challenging integrations 
            in various international projects and products.
        `,
    imageIcons: [
      {
        img: nodeImg,
        title: "",
        url: urls.node,
      },
      {
        img: dockerImg,
        title: "Docker",
        url: urls.docker,
      },
      {
        img: angularImg,
        title: "Angular",
        url: urls.angular,
      },
      {
        img: apiImg,
        title: "API Integrations",
        url: "#",
      },
    ],
    category: "Webapp Development",
    link: "hotovo",
  },
  {
    imageTop: vin,
    title: "NHTSA Vehicle Identification Number Encoder API",
    description: `
        An API to encode limited vehicle information like vehicle model and year into specific Vehicle Identification Number. 
        Needed to reverse-engineer NHTSA legacy database functions and develop an 
        effective algorithm to achieve compatible encoding.`,
    imageIcons: [
      {
        img: sqlImg,
        title: "",
        url: "#",
      },
      {
        img: mssqlImg,
        title: "",
        url: urls.sqlserver,
      },
      {
        img: goImg,
        title: "GoLang",
        url: urls.golang,
      },
      {
        img: dockerImg,
        title: "Docker",
        url: urls.docker,
      },
    ],
    category: "API Development",
    link: "vin-encoder",
  },
];

function WorkGroup() {
  useLayoutEffect(() => {
    const cards = document.querySelectorAll(".work");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((ele) => {
        if (ele.isIntersecting) {
          ele.target.classList.add("animate-moveup");
        }
      });
    });
    cards.forEach((card) => {
      observer.observe(card);
    });
    return () => {
      cards.forEach((card) => {
        observer.disconnect(card);
      });
    };
  }, []);
  const contents = ["1", "2", "3", "4", "5", "6"];

  return (
    <div className="md:px-20">
      {/* <div
        style={{ animationDelay: 3 / 5 + "s" }}
        className="container mx-auto px-2 md:px-0  work group flex flex-col w-full rounded-lg opacity-0 overflow-hidden transition-shadow"
      ></div> */}
      <div className="container mx-auto px-2 md:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 py-16 lg:gap-8 lg:flex-wrap">
        {contents.map((x, i) => (
          <Work key={i} index={i + 1} content={cardContent[i]} />
        ))}
      </div>
    </div>
  );
}

export default WorkGroup;
