import React, { useEffect } from "react";
import dots from "../assets/images/dots.png";
import ximg from "../assets/images/x.png";
import timg from "../assets/images/t.png";
import oimg from "../assets/images/o.png";
import rimg from "../assets/images/r.png";
import personal from "../assets/images/personal.svg";
import useNavbarEffect from "../hooks/useNavbarEffect";

function PrivacyPage() {
  useNavbarEffect(true, false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <div className="relative bg-primary py-40 overflow-hidden">
        <div className="container relative z-10 mx-auto px-4">
          <div className="text-white text-center mb-8">
            <h1 className="font-bold font-heading  text-4xl md:text-6xl mt-3 mb-5 capitalize">
              Privacy Notice
            </h1>
          </div>
        </div>
        <div className="absolute top-1/2 left-1/4 animate-grow z-0">
          <img src={dots} alt="Decorative dots" className="max-w-full h-auto" />
        </div>
        <div className="absolute top-12 left-1/2 animate-spin-slow z-0">
          <img src={timg} alt="Letter T" className="max-w-full h-auto" />
        </div>
        <div className="absolute bottom-0 right-1/3 animate-spin animate-movearound z-0">
          <img src={ximg} alt="Letter X" className="max-w-full h-auto" />
        </div>
        <div className="absolute bottom-1/3 left-10 animate-spin-slow z-0">
          <img src={rimg} alt="Letter R" className="max-w-full h-auto" />
        </div>
        <div className="absolute bottom-20 left-1/3 animate-movearound z-0">
          <img src={oimg} alt="Letter O" className="max-w-full h-auto" />
        </div>
        <div className="absolute top-1/2 right-20 animate-spin-slow z-0">
          <img src={timg} alt="Letter T" className="max-w-full h-auto" />
        </div>
        <img
          src={personal}
          alt="personal illustration"
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-250 opacity-10 w-4/6 md:w-2/5 max-h-96"
        />
      </div>
      <div className="mt-10 px-4 md:px-4 container relative mx-auto md:mt-16 sm:px-0 sm:mt-5 md:text-left">
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          Mereb Technologies operates the Mereb Technologies website, which is
          stated as our SERVICE from now on.
        </p>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          This page is used to inform website visitors regarding our policies
          with the collection, use, and disclosure of Personal Information if
          anyone decided to use our Service, the Mereb Technologies website. If
          you choose to use our Service, then you agree to the collection and
          use of information in relation with this Notice. The Personal
          Information that we collect are used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Notice.
        </p>
      </div>

      <div className="mt-10 mb-10 px-4 md:px-4  container relative mx-auto md:mt-8 sm:px-0 sm:mt-5 md:text-left">
        <h2 className="uppercase text-primary text-2Fxl md:text-3xl font-bold font-heading">
          Information Collection and Use
        </h2>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          For a better experience while using our Service, we may require you to
          provide us with certain personally identifiable information, including
          but not limited to your name, phone number, and postal address. The
          information that we collect will be used to contact or identify you.
        </p>
      </div>
      <div className="mt-10 mb-10 px-4 md:px-4  container relative mx-auto md:mt-8 sm:px-0 sm:mt-0 md:text-left">
        <h2 className="uppercase text-primary text-2xl md:text-3xl font-bold font-heading">
          Log Data
        </h2>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          We want to inform you that whenever you visit our Service, we collect
          information that your browser sends to us that is called Log Data.
          This Log Data may include information such as your computer's Internet
          Protocol (“IP”) address, browser version, pages of our Service that
          you visit, the time and date of your visit, the time spent on those
          pages, and other statistics.
        </p>
      </div>
      <div className="mt-10 mb-10 px-4 md:px-4  container relative mx-auto md:mt-8 sm:px-0 sm:mt-0 md:text-left">
        <h2 className="uppercase text-primary text-2xl md:text-3xl font-bold font-heading">
          Cookies
        </h2>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          Cookies are files with small amount of data that is commonly used an
          anonymous unique identifier. These are sent to your browser from the
          website that you visit and are stored on your computer's hard drive.
        </p>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          Our website uses these “cookies” to collection information and to
          improve our Service. You can know when a cookie is being sent to your
          computer. If you choose to refuse our cookies, you may not be able to
          use some portions of our Service.
        </p>
      </div>
      <div className="mt-10 mb-10 px-4 md:px-4  container relative mx-auto md:mt-8 sm:px-0 sm:mt-0 md:text-left">
        <h2 className="uppercase text-primary text-2xl md:text-3xl font-bold font-heading">
          Service Providers
        </h2>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <ul className="list-disc mx-5 ">
          <li className="text-grey-600 mt-3  text-sm md:text-base font-body">
            To facilitate our Service
          </li>
          <li className="text-grey-600 mt-3  text-sm md:text-base font-body">
            To provide the Service on our behalf
          </li>
          <li className="text-grey-600 mt-3  text-sm md:text-base font-body">
            To perform Service-related services or
          </li>
          <li className="text-grey-600 mt-3  text-sm md:text-base font-body">
            To assist us in analyzing how our Service is used
          </li>
        </ul>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          We want to inform our Service users that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>
      </div>
      <div className="mt-10 mb-10 px-4 md:px-4  container relative mx-auto md:mt-8 sm:px-0 sm:mt-0 md:text-left">
        <h2 className="uppercase text-primary text-2xl md:text-3xl font-bold font-heading">
          Security
        </h2>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>
      </div>
      <div className="mt-10 mb-10 px-4 md:px-4 container relative mx-auto md:mt-8 sm:px-0 sm:mt-0 md:text-left">
        <h2 className="uppercase text-primary text-2xl md:text-3xl font-bold font-heading">
          Links to Other Sites
        </h2>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          Our Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Notice of these websites. We have no control
          over, and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>
      </div>
      <div className="mt-10 mb-10 px-4 md:px-4 container relative mx-auto md:mt-8 sm:px-0 sm:mt-0 md:text-left">
        <h2 className="uppercase text-primary text-2xl md:text-3xl font-bold font-heading">
          Changes to This Privacy Notice
        </h2>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          We may update our Privacy Notice from time to time. Thus, we advise
          you to review this page periodically for any changes. We will notify
          you of any changes by posting the new Privacy Notice on this page.
          These changes are effective immediately, after they are posted on this
          page.
        </p>
      </div>
      <div className="mt-10 mb-16 px-4 md:px-4 container relative mx-auto md:mt-8 sm:px-0 sm:mt-0 md:text-left">
        <h2 className="uppercase text-primary text-2xl md:text-3xl font-bold font-heading">
          Contact Us
        </h2>
        <p className="text-grey-600 mt-3  text-sm md:text-base font-body">
          If you have any questions or suggestions about our Privacy Notice, do
          not hesitate to contact us.
        </p>
      </div>
    </div>
  );
}
export default PrivacyPage;
