import React, { useEffect } from "react";
import { MainBody, Services } from "../components";
import useNavbarEffect from "../hooks/useNavbarEffect";

function LandingPage() {
  useNavbarEffect(false, true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainBody />
      <Services />
    </>
  );
}

export default LandingPage;
