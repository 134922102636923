import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import promoVideo from "../assets/videos/promo_video.mp4";
import img from "../assets/images/home.webp";
import { Helmet } from "react-helmet";

const classes = [
  "animate-appearup",
  "animate-appearup-2",
  "animate-moveleft",
  "animate-appearup",
];

function MainBody() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useLayoutEffect(() => {
    const cards = document.querySelectorAll(".main-content");
    const imgElement = document.querySelector(".main-img");
    const video = document.getElementById("videoId");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, i) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes[i % classes.length]);
        }
      });
    });

    cards.forEach((card) => {
      observer.observe(card);
    });

    if (imgElement) {
      observer.observe(imgElement);
    }

    const handleVideoLoad = () => {
      video.classList.add("loaded");
    };

    if (video) {
      video.addEventListener("canplay", handleVideoLoad);
    }

    return () => {
      observer.disconnect();
      if (video) {
        video.removeEventListener("canplay", handleVideoLoad);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Mereb Technologies | Outsourcing & Software Development Services
        </title>
        <meta
          name="description"
          content="Mereb Technologies specializes in outsourced software development for global clients. Partner with Mereb Technologies for expert solutions and quality services."
        />
        <meta
          name="keywords"
          content="Mereb Technologies, outsourcing, software development, IT services"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://merebtechnology.com/" />
        <meta
          property="og:title"
          content="Mereb Technologies | Software Development and Outsourcing Company"
        />
        <meta
          property="og:description"
          content="Mereb Technologies specializes in outsourcing software development for global clients across various sectors. Partner with us for top-notch solutions and excellent service."
        />
        <meta
          property="og:image"
          content="https://merebtechnology.com/mereb-logo.jpg"
        />
        <meta property="og:locale" content="en_US" />
        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Mereb Technologies | Software Development and Outsourcing Company"
        />
        <meta
          name="twitter:description"
          content="Mereb Technologies specializes in outsourcing software development for global clients across various sectors. Partner with us for top-notch solutions and excellent service."
        />
        <meta
          name="twitter:image"
          content="https://merebtechnology.com/mereb-logo.jpg"
        />
      </Helmet>
      <section className="relative bg-main bg-cover min-h-screen pb-20 md:pb-0 flex justify-center items-center overflow-hidden">
        <div className="video-container">
          <img
            src={img}
            alt="Mereb Technologies outsourcing services background"
            title="Mereb Technologies outsourcing services background"
            width="100%"
            height="100%"
            className={`video-bg ${isImageLoaded ? "loaded" : ""} object-cover`}
            onLoad={() => setIsImageLoaded(true)}
          />
          <video
            autoPlay
            muted
            loop
            playsInline
            id="videoId"
            className="video-element"
          >
            <source src={promoVideo} type="video/mp4" />
          </video>
        </div>
        <div className="absolute z-0 p-6 text-white pb-20 md:pb-0">
          <div className="relative z-10 md:px-20 mt-32 md:mt-0">
            <div className="container mx-auto flex flex-col md:flex-row items-center">
              <div className="flex-1 px-6 md:px-0 main-content opacity-0">
                <h1 className="visually-hidden">
                  Mereb Technologies: Outsourcing Software Development Services
                  for Global Clients
                </h1>
                <h2 className="main-header-h2 font-bold font-heading text-3xl text-center md:text-left md:text-5xl mb-6 text-white">
                  Your software is as good as the people behind it
                </h2>
                <h3 className="main-header-p font-light font-body text-lg md:text-xl text-center md:text-left text-white">
                  With the synergy of excellent communication, experienced
                  engineers, and the latest technology trends, we provide you
                  with the best quality services and products.
                </h3>
                <div className="flex flex-wrap font-extrabold mt-14 mb-8 text-center md:text-left">
                  <Link
                    to="/contact"
                    className="mr-6 py-4 px-7 rounded-md bg-merebBrandColor text-white hover:bg-primaryMedium whitespace-nowrap transition-colors"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className="mx-6 md:mx-0 mt-6 md:mt-0 flex-1 main-img opacity-0"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainBody;
