import React, { useEffect } from "react";
import { WorkGroup, CommonHeader } from "../components";
import tabs from "../assets/images/tabs.svg";
import useNavbarEffect from "../hooks/useNavbarEffect";

function PortfolioPage() {
  useNavbarEffect(true, false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CommonHeader
      title={"Projects"}
      subTitle={" Selected Success Stories from Our 100+ Project Portfolio"}
      src={tabs}
      alt={"Tabs illustration"}
    >
      <WorkGroup />
    </CommonHeader>
  );
}

export default PortfolioPage;
