import { useEffect } from "react";

const useNavbarEffect = (initialBg = false, toggleOnScroll = true) => {
  useEffect(() => {
    const nav = document.querySelector(".navbar");

    if (initialBg) {
      nav.classList.add("bg-primary");
    } else {
      nav.classList.remove("bg-primary");
    }

    const handleScroll = () => {
      const top = 70;
      if (window.scrollY >= top) {
        nav.classList.add("bg-primary");
      } else if (toggleOnScroll) {
        nav.classList.remove("bg-primary");
      }
    };

    if (toggleOnScroll) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (toggleOnScroll) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [initialBg, toggleOnScroll]);
};

export default useNavbarEffect;
