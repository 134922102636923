import React from "react";
import office from "../assets/teams/group_photo.jpg";

function AboutCompany() {
  return (
    <div className="bg-indigo-50 py-24">
      <div className="container w-full mx-auto px-10 flex flex-wrap flex-row justify-between items-center">
        <div className="mb-8">
          <h2 className="text-primary text-3xl md:text-5xl font-heading">
            Our Company
          </h2>
          <h3 className="text-black font-bold font-heading text-2xl md:text-2xl mt-3 mb-3 md:max-w-2xl capitalize">
            We Provide Expert Development Service All Over The World
          </h3>
          <p className="text-gray-700  about-section-p mx-auto mt-4 font-body">
            We are passionate teams of engineers striving to solve intractable
            business problems in creative and pragmatic ways.
          </p>
          <p className="text-gray-700 about-section-p mx-auto mt-4 font-body">
            We believe meaningful value can be provided through long-term
            commitment. We don’t want to be an extension of your team, we strive
            to be a part of it, sharing your vision.
          </p>
          <p className="text-gray-700 about-section-p mx-auto mt-4">
            We take on business challenges, learn lessons, do self-improving,
            and always move forward to rise above them. Two things we value are
            ensuring a cultivating environment for our teams and the
            satisfaction of our clients.
          </p>
          <h3 className="text-black font-bold font-heading text-2xl md:text-2xl mt-10 mb-3 capitalize">
            Our Vision
          </h3>
          <p className="text-gray-700 about-section-p mx-auto mt-4 font-body">
            To be a global leader in software development, recognized for our
            innovation, collaboration, and commitment to excellence. We envision
            a future where our solutions drive progress, empower businesses, and
            create lasting impact.
          </p>
          <h3 className="text-black font-bold font-heading text-2xl md:text-2xl mt-10 mb-3 capitalize">
            Our Goals
          </h3>
          <ul className="list-disc list-inside text-gray-700  about-section-p mx-auto mt-4 font-body">
            <li>
              Deliver cutting-edge technology solutions that exceed client
              expectations.
            </li>
            <li>
              Foster a culture of continuous learning and development within our
              teams.
            </li>
            <li>
              Build long-term partnerships based on trust, transparency, and
              shared success.
            </li>
            <li>
              Drive sustainable growth while contributing positively to society
              and the environment.
            </li>
          </ul>
        </div>
        <div className="relative group w-2/5 about-img-section mx-auto md:mx-0 md:mt-2">
          <div className="invisible img-wrap  transition-transform duration-300 group-hover:-translate-x-1.5 group-hover:-translate-y-2.5 absolute -top-8 -left-8 bg-primary w-3/5 h-28 rounded" />
          <div className="invisible img-wrap  transition-transform duration-300 group-hover:translate-x-1.5 group-hover:translate-y-2.5 absolute -bottom-8 -right-8 bg-black w-3/5 h-28 rounded" />
          <img
            src={office}
            alt="office"
            title="office image"
            className="relative w-full"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutCompany;
