import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo-3.png";

function Navbar() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="animate-pulldown navbar fixed top-0 left-0 -translate-y-full z-1200 w-full">
      <div
        className={`w-full text-white px-20 navbar-responsive-main ${
          open ? "bg-primary" : ""
        }`}
      >
        <div className="flex flex-col navbar-responsive  mx-auto md:items-center md:justify-between md:flex-row md:py-2">
          <div className="p-3 flex flex-row items-center md:px-0 justify-between">
            <Link onClick={clickMenuButton} to="/" className="text-2xl">
              <img
                src={logo}
                alt="Mereb Technologies logo - Software Development and Outsourcing"
                className="w-32 md:w-40"
                width="256"
                height="128"
              />
            </Link>
            <button
              aria-label="Open Menu"
              type="button"
              className={`hamburger md:hidden rounded-lg focus:outline-none focus:shadow-outline ${
                open ? "open" : ""
              }`}
              onClick={() => setOpen(!open)}
            >
              <div className="line line1" />
              <div className="line line2" />
              <div className="line line3" />
            </button>
          </div>
          <nav
            className={`${
              open ? "h-49 pb-4 visible bg-primary " : "h-0 pb-0 invisible"
            } transition-all duration-350 flex flex-col flex-grow md:h-full md:pb-0 md:flex md:justify-end md:flex-row
						`}
          >
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 md:visible md:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/"
            >
              Home
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 md:visible md:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/portfolio"
            >
              Projects
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 md:visible md:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/about"
            >
              About Us
            </NavLink>

            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 md:visible md:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/our-team"
            >
              Our Team
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 md:visible md:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/insights"
            >
              Insights
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 md:visible md:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/jobs"
            >
              Career
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 md:visible md:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/contact"
            >
              Contact Us
            </NavLink>
          </nav>
        </div>
      </div>
    </nav>
  );

  function clickMenuButton() {
    if (window.innerWidth > 768) return;
    const menuRef = document.querySelector(".menu-btn");
    menuRef.click();
  }
}

export default Navbar;
